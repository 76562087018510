import React from 'react'
import PriceBlock from '../PriceBlock/PriceBlock'
import css from './Price.module.css'

function Price() {

    const list = [
        ['Аудит сайта', 'Консультация', 'Планирование', 'SEO оптимизация', 'Интеграция Bitrix24'],
        ['Правки по верстке', 'Правки по функционалу', 'Перенос сайта', 'Регистрация домена', 'Интеграция CRM системы'],
        ['Разработка дизайна', 'Верстка сайта', 'Интернет-магазин', 'Создание Блога', 'Веб-приложение']
    ]
    
    return (
        <div className={css.price}>
            <div className={css.title}>
                <h2>Расценки и тарифы</h2>
            </div >
            <div className={css.blocks}>
                <PriceBlock title="Бонусные услуги" price="FREE" list={list[0]} note='* Данные услуги предоставляются бесплатно, и не входят в сметную стоимость только при заказе проекта.' />
                <PriceBlock title="Часовая оплата" price="300" list={list[1]} note='* минимальная оплата за 2 часа' />
                <PriceBlock title="Проектная работа" price="3000" list={list[2]} note='*  Указана сумма минимального входа, цена зависит от объема работ' />
            </div>
        </div >
    )
}

export default Price
