import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
// Componens -------------------------------------------/
import OfferBlock from './OfferBlock/OfferBlock'
import FormOrder from './FormOrder/FormOrder'
import Progress from './Progress/Progress';
import Contacts from './Contacts/Contacts';
import WhyIAm from './WhyIAm/WhyIAm';
import MySevices from './MySevices/MySevices';
import Price from './Price/Price';
import Reviews from './Reviews/Reviews'
import Portfolio from './Portfolio/Portfolio'
import MyHistory from './MyHistory/MyHistory'
import News from './News/News'
import Footer from './Footer/Footer';
import MyScroll from '../../Others/MyScroll/MyScroll';
// CSS
import css from './SinglePage.module.css'
// Redux
import { useDispatch } from 'react-redux';
import { reset } from '../../../redux/reducers/Scroll';

const SinglePage = () => {
    const dispatch = useDispatch()
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(!inProp)
        dispatch(reset())
    }, [])

    return (
        <MyScroll step={175}>
            <main className={css.main}>
                <CSSTransition in={inProp} classNames="component" timeout={200} appear={false}>
                    <>
                        <OfferBlock />
                        <Progress />
                        <WhyIAm />
                        <MySevices />
                        <Portfolio />
                        <Price />
                        <Reviews />
                        <MyHistory />
                        <News />
                        <Contacts />
                        <FormOrder />
                        <Footer />
                    </>
                </CSSTransition >
            </main>
            <div className={css.backgroundImage} >
                <div className={css.background} ></div>
            </div>
        </MyScroll>
    );
}

export default SinglePage;
