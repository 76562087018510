import { gql } from '@apollo/client';

export const MY_ARTICLES = gql`
    query {
        postsCollection(where: {category: "Почему я?"}){
            items{
                slug
                title
                description
            }
        }
    }
`