// React
import React, { useState } from 'react';
import { whatsappNumber } from '../../../../constants';
// Component
import Button from '../../../Others/Button/Button';
// CSS
import style from './PriceBlock.module.css';

const PriceBlock = React.memo(({ title, price, list, note }) => {

    const [text, setText] = useState('ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ');

    function handlerButton() {
        setText(whatsappNumber)
    }

    // JSX
    return (
        <div className={style.block}>
            <div className={style.title}>
                <h3>{title}</h3>
            </div>
            <div className={style.rate}>
                <h4>{price}</h4>
                <span>RUB</span>
            </div>
            <div className={style.listServices}>
                <ul>
                    {list.map((item, id) => <li key={id}> {item} </li>)}
                </ul>
            </div>
            <div className={style.button} onClick={handlerButton}>
                <Button text={text} />
            </div>
            <div className={style.footnote}>
                {note}
            </div>
        </div >
    );
})

export default PriceBlock;
