import React, { useEffect } from 'react';
// My
import SidebarLeft from '../SidebarLeft/SidebarLeft';
import './SidebarLeftMobile.css';
// Redux
import { openLeftSidebar, closeLeftSidebar } from '../../../redux/reducers/ShowMobileLeftSidebar';
import { useDispatch, useSelector } from 'react-redux';


const SidebarLeftMobile = () => {

    const { open, showMobileLeftSidebar, myClass, displayValue } = useSelector(({ showMobileLeftSidebar }) => showMobileLeftSidebar)
    const { height } = useSelector(({ commonParams }) => commonParams)
    const dispatch = useDispatch()

    useEffect(() => {
        if (showMobileLeftSidebar) {
            dispatch(openLeftSidebar())
        } else {
            dispatch(closeLeftSidebar())
        }
    }, [showMobileLeftSidebar, height])

    return (
        <div className={myClass} style={{ height: `${height}px` }}>
            <SidebarLeft open={open} sbMobile={true} mobileStep={true} display={displayValue} />
        </div>
    );
}

export default SidebarLeftMobile;
