import { createSlice } from '@reduxjs/toolkit';

// whith mobile sidebar too ....
const showMobileLeftSidebar = createSlice({

    name: "showMobileLeftSidebar",

    initialState: {
        myClass: "inactive",
        showMobileLeftSidebar: false,
        open: false,
        displayValue: 'none'
    },

    reducers: {
        setShowLeftSidebar(state, action) {
            state.showMobileLeftSidebar = action.payload
        },
        openLeftSidebar(state) {
            state.myClass = "activeMobileSidebar"
            state.open = true
            state.displayValue = 'flex'
            state.showMobileLeftSidebar = true
        },
        closeLeftSidebar(state) {
            state.myClass = "inactiveMobileSidebar"
            state.open = false
            state.showMobileLeftSidebar = false
        }
    }
})

export const { openLeftSidebar, closeLeftSidebar, setShowLeftSidebar } = showMobileLeftSidebar.actions

export default showMobileLeftSidebar.reducer