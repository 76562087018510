// React
import React, { useEffect, useState } from 'react'
// Components
import CommonBlock from '../CommonBlock/CommonBlock'
import Loader from '../../../Others/Loader/Loader';
//  GraphQL
import { useQuery } from '@apollo/client';
import { MY_SERVICES } from './Queries';
// Css
import css from './MySevices.module.css'

function MySevices() {

    // Get data from GraphQL
    const { loading, error, data } = useQuery(MY_SERVICES)

    // Declarate varibales
    const [articles, setArticles] = useState([]);

    // After mounting component
    useEffect(() => {
        if (!loading && !error) {
            setArticles(data.postsCollection.items)
        } else {
            console.log(error)
        }
    }, [data])

    // JSX
    return (
        <div className={css.myServices}>
            <div className={css.title}>
                <h2>Мои услуги</h2>
            </div >
            <div className={css.blocks}>
                {loading
                    ? <div style={{ margin: "0 10 20 10px", width: "100%" }}><Loader /></div>
                    : articles.map((article, id) => <CommonBlock key={id} article={article} myServices={true} />)
                }
            </div>
        </div >
    )
}

// Export
export default MySevices