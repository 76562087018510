// React
import React from 'react';
// Css
import css from './FormOrder.module.css';
// Form hook
import { Formik, Form, Field, ErrorMessage } from 'formik';
// Validate library
import * as Yup from 'yup';

// Begin data
const initialValues = { name: '', email: '', msg: '' }

// Send data
const onSubmit = (values, onSubmitProps) => {
    onSubmitProps.resetForm()
}

// Validation
const validationSchema = Yup.object({
    name: Yup.string().required('Обязательное поле для отправки'),
    email: Yup.string().email('Не верный формат адреса').required('Обязательное поле для отправки'),
    msg: Yup.string().required('Обязательное поле для отправки'),
})

// Component
const FormOrder = () => {
    // JSX
    return (
        <div className={css.formOrder}>
            <div className={css.title}>
                <h2>Заказать проект</h2>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}
                validateOnChange={true} validateOnBlur={false} validateOnMount>
                {
                    ({ errors, touched, isValid }) => {

                        return (
                            <div className={css.block}>
                                <Form>
                                    {/* Name */}
                                    <div className={(errors.name && touched.name) ? css.inputSectionError : css.inputSection}>
                                        <label htmlFor="name">
                                            <i className={css.name}></i>
                                        </label>
                                        <Field id="name" type="text" name="name" placeholder="Имя" />
                                    </div>
                                    <ErrorMessage name="name" component="div" className={css.error} />

                                    {/* E-mail */}
                                    <div className={(errors.email && touched.email) ? css.inputSectionError : css.inputSection}>
                                        <label htmlFor="email">
                                            <i className={css.mail}></i>
                                        </label>
                                        <Field id="email" type="email" name="email" placeholder="E-mail" />
                                    </div>
                                    <ErrorMessage name="email" component="div" className={css.error} />

                                    {/* Body Message */}
                                    <div className={(errors.msg && touched.msg) ? css.inputSectionError : css.inputSection}>
                                        <label className={css.textarea} htmlFor="msg">
                                            <i className={css.mail}></i>
                                        </label>
                                        <Field as="textarea" id="msg" name="msg" placeholder="Заполните заявку" />
                                    </div>
                                    <ErrorMessage name="msg" component="div" className={css.error} />

                                    {/* BUTTON */}
                                    <div>
                                        <button className={css.btnOffer} type="submit" disabled={!isValid}>
                                            {isValid ? 'Отправить сообщение' : 'Заполните поля'}
                                        </button>
                                    </div>

                                </Form>
                            </div>
                        )
                    }
                }
            </Formik>
        </div>

    );
}

export default FormOrder;