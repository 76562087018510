import { createSlice } from "@reduxjs/toolkit";

const scrollSidebarSlice = createSlice({

    name: "scrollSidebar",

    initialState: {
        sbCounter: 0,
        sbStep: 0,
        sbTranslateValue: 0,
        sbStartPoint: 0,
        sbEndPoint: 0,
        sbHeightScroll: 0,
        sbHeightChildScroll: 0,
        sbSensitivity: 20,
        sbTouchStart: { x: 0, y: 0 },
        sbTouchPosition: { x: 0, y: 0 },
    },

    reducers: {
        setSbTranslateValue(state, action) {
            state.sbTranslateValue = action.payload
        },
        sbIncrement(state) {
            state.sbTranslateValue = state.sbTranslateValue + state.sbStep
            state.sbCounter = state.sbCounter + state.sbStep
        },
        sbDecrement(state) {
            state.sbTranslateValue = state.sbTranslateValue - state.sbStep
            state.sbCounter = state.sbCounter - state.sbStep
        },
        sbReset(state) {
            state.sbTranslateValue = 0
            state.sbCounter = 0
        },
        sbSetHeightScroll(state, action) {
            state.sbHeightScroll = action.payload
        },
        sbSetHeightChildScroll(state, action) {
            state.sbHeightChildScroll = action.payload
        },
        sbSetEndPoint(state) {
            state.sbEndPoint = state.sbHeightScroll - state.sbHeightChildScroll
        },
        sbSetStep(state, action) {
            state.sbStep = action.payload
        },
        sbSetTouchStart(state, action) {
            state.sbTouchStart = action.payload
            state.sbTouchPosition = action.payload
        },
        sbSetTouchPosition(state, action) {
            state.sbTouchPosition = action.payload
        },
    }
})

export const {
    sbIncrement,
    sbDecrement,
    sbReset,
    sbSetHeightScroll,
    sbSetHeightChildScroll,
    sbSetEndPoint,
    sbSetStep,
    sbSetTouchStart,
    sbSetTouchPosition,
    setSbTranslateValue
} = scrollSidebarSlice.actions

export default scrollSidebarSlice.reducer
