// React
import React, { useEffect, useState } from 'react';
// React DOM
import { NavLink } from 'react-router-dom';
// Css transition
import { CSSTransition } from 'react-transition-group';
import { whatsappNumber } from '../../../../constants';
// My components
import Button from '../../../Others/Button/Button';
import Ribbon from '../../../Others/Ribbon';
// My css
import css from './CommonBlock.module.css';


const CommonBlock = React.memo(({ article, myServices }) => {

    // Decalarate varibales
    const [inProp, setInProp] = useState(false);
    const [text, setText] = useState('ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ');

    function handlerButton() {
        setText(whatsappNumber)
    }

    // After mounting component
    useEffect(() => {
        setInProp(!inProp)
    }, [])

    // JSX
    return (
        <CSSTransition in={inProp} classNames="component" timeout={100} appear={true}>
            <div className={`${css.block} box`} >
                {article.title === 'Аудит сайта' && <Ribbon text="бесплатно" />}
                <div className={css.title}>
                    <h3>{article.title.substr(0, 20) + '...'}</h3>
                </div>
                <div className={css.text}>
                    {article.description.substr(0, 100) + '...'}
                </div>
                {
                    myServices
                        ? <div onClick={handlerButton} style={{ cursor: 'pointer' }}><Button text={text} /></div>
                        : <NavLink to={`/blog/article/${article.slug}`}> <Button text="ПОДРОБНЕЕ" /></NavLink>
                }
            </div >
        </CSSTransition>
    );
})

// Export
export default CommonBlock;