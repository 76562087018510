import React from 'react';
// React dom
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// My ccs
import css from './SidebarRight.module.css';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from '../../../redux/reducers/ShowRightSidebar';
import { setLang } from '../../../redux/reducers/CommonParams';

const SidebarRight = React.memo(({ history: { location: { pathname } } }) => {

    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();

    const { translateValue, widthValue, open } = useSelector(({ showRightSidebar }) => showRightSidebar)
    const { height, lang } = useSelector(({ commonParams }) => commonParams)

    const changeLanguage = (event) => {
        event.stopPropagation()
        if (lang === 'ru') {
            i18n.changeLanguage('en');
            dispatch(setLang('en'));
        }
        if (lang === 'en') {
            i18n.changeLanguage('ru');
            dispatch(setLang('ru'));
        }
    }

    return (
        <div className={css.wrap} style={{ width: `${widthValue}px`, transform: `translateX(${translateValue}px)` }}>
            <aside className={css.aside} style={{ height }} onClick={() => { dispatch(toggle()) }}>
                <div className={css.icon}>
                    {
                        open ?
                            <div className="open">
                                <div className={css.dote}></div>
                                <div className={css.dote}></div>
                                <div className={css.dote}></div>
                            </div>
                            :
                            <div className={css.close}>
                                <div className={css.line}></div>
                                <div className={css.line}></div>
                            </div>
                    }
                </div>
                <div className={css.data}>

                    <span className={css.text} style={open ? { opacity: '1' } : { opacity: '0' }}>
                        {(pathname === '/blog') ? 'blog' : 'home'}
                    </span>

                    <ul className={css.navList} >
                        <li style={open ? { marginLeft: '40px' } : { marginLeft: '-60px' }}>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li style={open ? { marginLeft: '100px' } : { marginLeft: '-60px' }}>
                            <NavLink to="/blog">Blog</NavLink>
                        </li>
                    </ul>

                </div>
                <div className={css.lang}>
                    <button
                        disabled={lang === 'ru'}
                        onClick={changeLanguage}
                        className={lang === 'ru' ? css.ruActive : css.ru}
                    >
                        RU
                    </button>
                    <button
                        disabled={lang === 'en'}
                        onClick={changeLanguage}
                        className={lang === 'en' ? css.enActive : css.en}
                    >
                        EN
                    </button>
                </div>
            </aside>
        </div >
    );
})

export default withRouter(SidebarRight);
