import React from 'react';
import './Loader.css'

const wrap = {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
    transition: 'all 1s ease'
}
const Loader = () => {
    return (
        <div style={wrap}>
            <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
    );
}

export default Loader;
