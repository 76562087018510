import { gql } from '@apollo/client';

export const GET_POSTS = gql`
    query($skip: Int!, $limit: Int!){
        postsCollection(skip: $skip, limit:$limit, where: {category_not: "Мои услуги"}) {
            total
            items{
                slug
                title
                description
                image{
                    url
                }
            }
        }
    }
`