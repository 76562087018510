import React from 'react'
import './styles.css'

function Ribbon({ text }) {
    return (
        <div className="ribbon"><span>{text}</span></div>
    )
}

export default Ribbon
