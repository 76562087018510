// React
import React, { useEffect, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
// Css transition
import { CSSTransition } from 'react-transition-group';
// My components
import Button from '../../../Others/Button/Button';
import Loader from '../../../Others/Loader/Loader';
import Footer from '../../SinglePage/Footer/Footer'
import FormOrder from '../../SinglePage/FormOrder/FormOrder';
import MyScroll from '../../../Others/MyScroll/MyScroll';
// CSS
import css from './ArticlePage.module.css';
// GraphQl
import { GET_ARTICLE } from './Queries';
import { useQuery } from '@apollo/client';
// Contentfull cms
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// Redux
import { useDispatch } from 'react-redux';
import { reset } from '../../../../redux/reducers/Scroll';


const AUTHORNAME = 'Evgenii Seleznev' // HARD CODE :/

const ArticlePage = React.memo(({ myStyle, match, match: { params: { slug } } }) => {

    const dispatch = useDispatch()

    const { data, error } = useQuery(GET_ARTICLE, { variables: { slug } })
    console.log('data:', data)
    const [inProp, setInProp] = useState(false);
    const [article, setArticle] = useState(null)

    useEffect(() => {
        data ? setArticle(data.postsCollection.items["0"]) : console.log(error)
    }, [data])

    useEffect(() => {
        setInProp(!inProp)
        dispatch(reset())
    }, [])

    return (
        <MyScroll myStyle={myStyle} step={175}>
            <main className="main" >
                {!article
                    ? < Loader />
                    : <CSSTransition in={inProp} classNames="component" timeout={2000} appear={true}>
                        <div className={css.wrap}>
                            <div className={css.title}>
                                {article.title}
                            </div>
                            <div className={css.foto}>
                                <img src={`${article.image.url}`} />
                            </div>
                            <div className={css.content}>
                                <div className={css.article}>
                                    <div className={`${css.text} article`}>
                                        {documentToReactComponents(article.text.json)}
                                    </div>
                                    <div className={css.btn}>
                                        <NavLink to="/">
                                            <Button text="ВЕРНУТЬСЯ НА ГЛАВНУЮ" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={css.aside}>
                                    <div className={css.author}>
                                        <div className={css.leftBlock}>
                                            <ul>
                                                <li>Дата</li>
                                                <li>Автор</li>
                                                <li>Категория</li>
                                            </ul>
                                        </div>
                                        <div className={css.rightBlock}>
                                            <ul>
                                                <li>{(article.date).substr(0, 10)}</li>
                                                <li>{AUTHORNAME}</li>
                                                <li>{article.category}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={css.form}>
                                        <FormOrder />
                                    </div>
                                </div>
                            </div>
                            <div className={css.footer}>
                                <Footer />
                            </div>
                        </div>
                    </CSSTransition>
                }
            </main >
            <div className={css.backgroundImage}>
                <div className={css.background} />
            </div>
        </MyScroll>
    )
})

export default withRouter(ArticlePage)
