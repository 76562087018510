import React from 'react';
import css from './Footer.module.css';

const Footer = () => {
    return (
        <div className={css.footer}>
            <div className={css.block}>
                <div className={css.content}>
                    <span>
                        <p>{new Date().getFullYear()} Evgenii Seleznev</p>
                    </span>
                    <span className={css.mail}>
                        <p>ESSeleznev@Gmail.com</p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
