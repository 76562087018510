import { gql } from '@apollo/client';

const getMyEducations = gql`
    query{
        educationCollection{
            items{
                title
                period
                description
                specialty
                link
            }
        }
    }
`
const getMyProfessions = gql`
    query{
        professionsCollection {
            items {
                title
                period
                description
                post
                document {
                    url
                }
            }
        }
    }
`
export { getMyEducations, getMyProfessions } 