import { createSlice } from '@reduxjs/toolkit';

const showMobileRightSidebar = createSlice({
    name: "showMobileRightSidebar",
    initialState: {
        open: true,
        showMobileRightSidebar: false,
        translateValue: 200,
        displayValue: 'none',
        myClass: "inactiveRightSidebar"

    },
    reducers: {
        setShowMobileRightSidebar(state, action) {
            state.showMobileRightSidebar = action.payload
        },
        toogle(state, action) {
            if (action.payload) {
                state.myClass = "inactiveRightSidebar"
                state.open = true
                state.showMobileRightSidebar = false
            }
            else {
                state.myClass = "activeRightSidebar"
                state.open = false
                state.showMobileRightSidebar = true
            }
        }
    }
})

export const { setShowMobileRightSidebar, toogle } = showMobileRightSidebar.actions
export default showMobileRightSidebar.reducer