export const resources = {
  en: {
    translation: {
      "aboutMe": "A little about me"
    }
  },
  ru: {
    translation: {
      "aboutMe": "Не много обо мне"
    }
  }
};