import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sbDecrement, sbIncrement, sbSetHeightScroll, sbSetHeightChildScroll, sbSetEndPoint, sbSetStep, sbSetTouchStart, sbSetTouchPosition } from '../../../redux/reducers/ScrollSidebar';
import { decrement, increment, setHeightScroll, setHeightChildScroll, setEndPoint, setStep, setTouchStart, setTouchPosition } from '../../../redux/reducers/Scroll';
import './MyScroll.css'

const debounce = (cb, ms) => {
    let timeoutId;
    return function (args) {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            cb(args);
            clearTimeout(timeoutId);
        }, ms);
    };
}

function MyScroll({ children, step, sb, sbStyle }) {
    const myRef = useRef()
    const dispatch = useDispatch()

    const { height } = useSelector(({ commonParams }) => commonParams)
    const { translateValueMainBlock } = useSelector(({ showRightSidebar }) => showRightSidebar)

    const myStyle = {
        height: `${height}px`,
        transform: `translate(${translateValueMainBlock}px)`
    }

    const {
        counter,
        sensitivity,
        translateValue,
        startPoint, endPoint,
        touchStart, touchPosition } = useSelector(({ scroll }) => scroll)

    const {
        sbCounter,
        sbTranslateValue,
        sbStartPoint, sbEndPoint,
        sbTouchStart, sbTouchPosition } = useSelector(({ scrollSidebar }) => scrollSidebar);

    const handlerWheel = useCallback(
        (e) => {
            const evt = e.nativeEvent;
            // const isTouchPad = evt.wheelDeltaY ? evt.wheelDeltaY === -3 * evt.deltaY : evt.deltaMode === 0;
            // const scrollStep = isTouchPad ? sb ? 10 : 20 : step;

            dispatch(sb ? sbSetStep(step / 2) : setStep(step / 2))

            if ((e.deltaY < 0) && (sb ? sbCounter < sbStartPoint : counter < startPoint)) { // Блок вниз
                dispatch(sb ? sbIncrement(step / 2) : increment(step / 2))
            }

            if ((e.deltaY > 0) && (sb ? sbCounter >= sbEndPoint : counter >= endPoint)) { // Блок вверх
                dispatch(sb ? sbDecrement(step / 2) : decrement(step / 2))
            }
        },
        [counter, sbCounter, step, sbStartPoint, startPoint, sbEndPoint, endPoint],
    )


    const debounceHandlerWheel = useMemo(() => debounce(handlerWheel, 16), [handlerWheel]);

    function handlerKeywords(e) {
        dispatch(sb ? sbSetStep(step) : setStep(step))
        if ((e.code === "ArrowUp") && (sb ? sbCounter < sbStartPoint : counter < startPoint)) {
            // Блок вниз
            dispatch(sb ? sbIncrement(step) : increment(step))
        }
        if ((e.code === "ArrowDown") && (sb ? sbCounter >= sbEndPoint : counter >= endPoint)) {
            // Блок вверх
            dispatch(sb ? sbDecrement(step) : decrement(step))
        }
    }

    function handlerTouch(e) {
        dispatch(sb ? sbSetStep(step * 3) : setStep(step * 3))
        if (e.type === "touchstart") {
            //Получаем текущую позицию касания
            dispatch(sb ? sbSetTouchStart({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }) : setTouchStart({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }))
        }
        if (e.type === "touchmove") {
            //Получаем новую позицию
            dispatch(sb ? sbSetTouchPosition({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }) : setTouchPosition({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }))
        }
        if (e.type === "touchend") {
            checkAction()
        }
        function checkAction() {
            let distance = {
                x: (sb ? sbTouchStart.x : touchStart.x) - (sb ? sbTouchPosition.x : touchPosition.x),
                y: (sb ? sbTouchStart.y : touchStart.y) - (sb ? sbTouchPosition.y : touchPosition.y)
            }
            if (Math.abs(distance.y) > sensitivity) {
                if (distance.y < 0 && (sb ? sbCounter !== sbStartPoint : counter !== startPoint)) {                           //Блок вниз
                    dispatch(sb ? sbIncrement(step * 3) : increment(step * 3))
                }
                if (distance.y > 0 && (sb ? sbCounter >= sbEndPoint : counter >= endPoint)) {                           //Блок вверх
                    dispatch(sb ? sbDecrement(step * 3) : decrement(step * 3))
                }
            }
        }
    }

    useEffect(() => {
        // Get Element
        const ScrollPannel = myRef.current
        // Passive to store
        dispatch(sb ? sbSetHeightScroll(ScrollPannel.offsetHeight) : setHeightScroll(ScrollPannel.offsetHeight))
        dispatch(sb ? sbSetHeightChildScroll(ScrollPannel.firstElementChild.scrollHeight) : setHeightChildScroll(ScrollPannel.firstElementChild.scrollHeight))
        dispatch(sb ? sbSetEndPoint() : setEndPoint())
        // Move Element Child
        ScrollPannel.firstElementChild.style.transform = `translateY(${sb ? sbTranslateValue : translateValue}px)`
    }, [translateValue, sbTranslateValue, height]);

    useEffect(() => {
        const ScrollPannel = myRef.current
        ScrollPannel.addEventListener('transitionend', function (event) {
            if (event.propertyName === 'transform') {
                console.log("CSS Property completed: ", event.propertyName)
            }
        }, false);
    }, [])

    return (
        <div ref={myRef} style={sbStyle ? sbStyle : myStyle} className="myScrollPanel"
            onWheel={debounceHandlerWheel}
            onTouchMove={handlerTouch}
            onTouchStart={handlerTouch}
            onTouchEnd={handlerTouch}
            onKeyDown={handlerKeywords} tabIndex="0">
            {children}
        </div>
    )
}

export default React.memo(MyScroll)


