import { gql } from '@apollo/client';

export const MY_SERVICES = gql`
    query {
        postsCollection(where: {category: "Мои услуги"}){
            items{
                slug
                title
                description
            }
        }
    }
`