import React, { useEffect } from 'react'
import getUserLocale from 'get-user-locale';
import { changeLanguage } from 'i18next';
import { useDispatch } from 'react-redux';
import { setLang } from './redux/reducers/CommonParams';
import HomePage from './components/HomePage/HomePage';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const userLocale = getUserLocale();

        if (userLocale !== 'ru') {
            changeLanguage('en');
            dispatch(setLang('en'));
        }
    }, [])

    return (
        <HomePage />
    )
}
export default App
