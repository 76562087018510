import React from 'react'
import './styles.css'

function Tooltip({ children, text }) {
    return (
        <span data-tooltip={text} data-flow="right" style={{cursor: 'not-allowed'}}>{children}</span>
    )
}

export default Tooltip
