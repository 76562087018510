import { createSlice } from '@reduxjs/toolkit';

const ShowRightSidebar = createSlice({
    name: "ShowRightSidebar",
    initialState: {
        open: true,
        widthValue: 0,
        translateValue: 0,
        translateValueMainBlock: 0
    },
    reducers: {
        setShowMobileRightSidebar(state, action) {
            state.ShowRightSidebar = action.payload
        },
        toggle(state) {
            switch (state.translateValue) {
                case 0: {
                    state.open = false
                    state.translateValue = -200
                    state.translateValueMainBlock = -200
                    break
                }
                case -200: {
                    state.open = true
                    state.translateValue = 0
                    state.translateValueMainBlock = 0
                    state.widthValue = 0
                    break
                }
                default:
                    break;
            }
        }
    }
})

export const { toggle } = ShowRightSidebar.actions
export default ShowRightSidebar.reducer