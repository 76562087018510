// React
import React from 'react'
// React dom
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
// Apollo
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
// Translate
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
// My
import App from './App'
import './index.css'
import { resources } from './resources/transalate';

const { REACT_APP_SPACE_ID: SPACE_ID, REACT_APP_ACCESS_TOKEN: ACCESS_TOKEN } = process.env

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/master?access_token=${ACCESS_TOKEN}`,
  cache: new InMemoryCache()
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "ru",
    fallbackLng: "ru",
    interpolation: { escapeValue: false }
  });

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
