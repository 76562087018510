// React
import React, { useEffect, useState } from 'react'

// Slick slider
import Slider from 'react-slick';
import { sliderSettings } from './SliderSettings';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// My css
import css from './News.module.css';
// My components
import NewsBlock from '../NewsBlock/NewsBlock';
import Loader from '../../../Others/Loader/Loader';
// GraphQl
import { useQuery } from '@apollo/client';
import { getMyPosts } from './Queries';


const News = () => {

    const { error, data } = useQuery(getMyPosts)
    const [news, setNews] = useState(null);

    useEffect(() => {
        data ? setNews(data.postsCollection.items) : console.log(error)
    }, [data])

    return (
        <div className={css.news} >
            <div className={css.title}>
                <h2>Статьи</h2>
            </div >
            <Slider {...sliderSettings} >
                {news ?
                    news.map((article, id) => <NewsBlock {...article} key={id} blog={false} />)
                    : <Loader />}
            </Slider>
        </div >
    );
}

export default News
