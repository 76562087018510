import React, { useEffect, useState } from 'react'
import CommonBlock from '../CommonBlock/CommonBlock'
import css from './WhyIAm.module.css'
import Loader from '../../../Others/Loader/Loader';
import { useQuery } from '@apollo/client';
import { MY_ARTICLES } from './Queries';

function WhyIAm() {

    const { loading, error, data } = useQuery(MY_ARTICLES)

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (!loading && !error) {
            setArticles(data.postsCollection.items)
        } else {
            console.log(error)
        }
    }, [data])

    return (
        <div className={css.whoIam} >
            <div className={css.title}>
                <h2>Почему я?</h2>
            </div >
            <div className={css.blocks}>
                {
                    (loading)
                        ? <Loader />
                        : articles.map((article, id) => <CommonBlock key={id} article={article} />)
                }
            </div>
        </div>

    )
}

export default WhyIAm
