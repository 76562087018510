import React from 'react'
import { NavLink } from 'react-router-dom';
// My Component
import Button from '../../../Others/Button/Button'
import css from './NewsBlock.module.css'
// Lazy laod
import { LazyLoadImage } from 'react-lazy-load-image-component';


const NewsBlock = React.memo(({ title, description, image, slug, blog }) => {

    return (
        <div className={blog ? 'news-block' : css.block}>
            <div className={css.imgPreview}>
                {/* <img src={url} alt="" /> */}
                <LazyLoadImage
                    alt={image.title}
                    height={image.height}
                    src={image.url}
                    width={image.width}
                />
            </div>
            <div className={css.contentBlock}>
                <div className={css.title}>{title.substr(0, 25) + '...'}</div>
                <div className={css.content}>{description.substr(0, 48) + '...'}</div>
                <NavLink to={`/blog/article/${slug}`}>
                    <Button text="ЧИТАТЬ ДАЛЕЕ ..." />
                </NavLink>
            </div>
        </div>
    );
})

export default NewsBlock;
