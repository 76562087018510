// My css
import css from './Reviews.module.css';

const NextArrow = ({ className, style, onClick }) => {
    const myStyles = {
        top: '104%', left: '95%',
        bottom: '0', right: '0',
        fontWeight: '600',
        color: '#888787',
        opacity: '0.5',
        cursor: 'pointer',
        zIndex: '9',
        display: "none"
    }
    return (
        <div className={className} style={{ ...style, ...myStyles }} onClick={onClick} />
    )
}

const PrevArrow = ({ className, style, onClick }) => {
    const myStyles = {
        top: '104%', left: '97%',
        bottom: '0', right: '0',
        fontWeight: '600',
        color: '#888787',
        opacity: '0.5',
        cursor: 'pointer',
        zIndex: '9',
        display: "none"
    }
    return (
        <div className={className} style={{ ...style, ...myStyles }} onClick={onClick} />
    )
}
// Slick Slider Settings
const sliderSettings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    width: '333',
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 2,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '5px' }}>
                <ul className={css.dots}>{dots}</ul>
            </div>
        )
    },
    customPaging: () => {
        const myStyles = {
            background: '#ffc107',
            width: '30px',
            height: '5px',
            display: 'inline-block',
            borderRadius: '25%',
            cursor: 'pointer'
        }
        return (
            <span style={myStyles} />
        )
    }
}



export default sliderSettings