import { gql } from "@apollo/client";

export const GET_ARTICLE = gql`
    query ($slug: String!){
        postsCollection(where: {slug: $slug}) {
            items {
            title
            category
            date
            text{
                json
            }
            image{
                url
                width
                height
            }
        }
    }
    }
`

