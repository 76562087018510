import { gql } from '@apollo/client';

export const myProjects = gql`
    query {
        projectsCollection{
            items{
                title
                description
                href
                image{
                    url
                    title
                    width
                    height
                }
                sys{
                    id
                }
                
            }
        }
    }
`