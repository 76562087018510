import { gql } from '@apollo/client'

export const myReviews = gql`
    query{
        reviewsCollection{
            items{
                author
                rate
                text
                sex
            }
        }
    }
`