
// React
import React, { useState } from 'react';
// CSS
import css from './ItemPortfolio.module.css';
// Lazy laod
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Component
const ItemPortfolio = React.memo(({ project }) => {

    // Declarate Data
    const [showMoveBlock, setShowMoveBlock] = useState(false)

    const onMouseOver = () => {
        setShowMoveBlock(true)
    }

    const onMouseOut = () => {
        setShowMoveBlock(false)
    }

    // JSX
    return (
        <div className={css.item} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <div className={showMoveBlock ? css.loop : css.loopHidden}>
                <i></i>
            </div>
            <div className={showMoveBlock ? css.imageRais : css.image}>
                {/* <img src={project.image.url} alt="" /> */}
                <LazyLoadImage
                    alt={project.image.title}
                    height={project.image.height}
                    src={project.image.url}
                    width={project.image.width}
                />
            </div>
            <div className={showMoveBlock ? css.moveBlock_visible : css.moveBlock_hidden}>
                <div className={css.title}>
                    {project.title}
                </div>
                <div className={css.text}>
                    {project.description}
                </div>
                <div className={css.refference}>
                    <a href={project.href} target="_blank">
                        {project.href}
                    </a>
                </div>
            </div>
        </div>
    );
})

// Export
export default ItemPortfolio;
