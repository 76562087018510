import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
// Css
import css from './OfferBlock.module.css'

const myStyle = {
    color: '#fafafc',
    fontWeight: '600'
}

const clientWidth = document.documentElement.clientWidth

const OfferBlock = () => {

    const { t, i18n } = useTranslation();

    // Screen width
    const [widthScreen] = useState(clientWidth);

    // counter
    const [count, setCount] = useState(0);
    const [countMass, setCountMass] = useState(0)

    // render text
    const [textForward, setTextForward] = useState('')
    const [textBack, setTextBack] = useState('')

    // where move? forward or back...
    const [goBack, setGoBack] = useState(false)

    // Set string
    const [myString] = useState([
        '  Это Single Page Aplication сделано на React JS',
        '  Разрабатываю веб приложения на React JS',
        '  Android и iOS приложения на React Native и Flutter'
    ])

    // Forward text
    useEffect(
        () => {
            if (goBack === false) {
                // Pause 2 sec
                if (count === myString[countMass].length) {
                    let timeOut = setTimeout(() => {
                        // initialization data for back and then begin
                        if (count === myString[countMass].length) {
                            setTextBack(textForward)
                            setGoBack(true)
                            setTextForward('')
                            setCount(0)
                            if (countMass < myString.length - 1) {
                                setCountMass(prevCountMass => prevCountMass + 1)
                            } else {
                                setCountMass(0)
                            }
                        }
                    }, 1200);
                    return () => {
                        clearTimeout(timeOut)
                    }
                }
                // run textForward 120msec
                else {
                    let timeOut = setTimeout(() => {
                        if (count < myString[countMass].length) {
                            // do counter
                            setCount(prevCount => prevCount + 1)
                            // do summation textForward
                            setTextForward(prevText => prevText + myString[countMass][count])
                        }
                    }, 120);
                    return () => {
                        clearTimeout(timeOut)
                    }
                }
            }
        }, [count, myString, goBack]);

    // Back textForward
    useEffect(() => {
        if (goBack) {
            let timeOut = setTimeout(() => {
                // cut last simbol
                setTextBack(textBack.substring(0, textBack.length - 1))
                // start begin
                if (textBack.length === 0) {
                    setGoBack(false)
                }
            }, 15);
            return () => {
                clearTimeout(timeOut)
            }
        }
    }, [goBack, textBack])

    // JSX
    return (
        <div className={css.offer}>
            <div className={css.bgImg}></div>
            <div className={css.title}>
                {widthScreen > 500
                    ? <h1>Frontend Developer<br />Mobile and web developing</h1>
                    : <h1> Mobile and web developer</h1>
                }

            </div>
            <div className={css.code}>
                <span className={css.code}>
                    <span style={myStyle}> &lt; </span>
                    code
                    <span style={myStyle}> &gt;</span>      &nbsp;&nbsp;
                    <span style={{ color: 'white' }}>
                        {goBack ? textBack : textForward}   &nbsp;
                    </span>
                    <span className={css.line}></span>      &nbsp;&nbsp;
                    <span style={myStyle}>&lt;/</span>
                    code
                    <span style={myStyle}>&gt;</span>
                </span>
            </div>
            <div className={css.button}>
                <NavLink to='/blog/article/about-me'>
                    <span> {t('aboutMe')} </span>
                </NavLink>
            </div>
        </div >
    );
}

export default OfferBlock;
