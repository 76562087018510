import { gql } from "@apollo/client";

export const getMyPosts = gql`
   query {
        postsCollection(limit:9, where: {AND: [{category_not: "Мои услуги"}, {category_not: "Обо мне"}]}){
            items{
                slug
                title
                description
                image{
                    url
                    title
                    width
                    height
                }
            }
        }
    }
`