import React, { useState, useEffect } from 'react';
import css from './Progress.module.css';

const Progress = () => {
    
    const [experience, setExpeirience] = useState(0);
    const [project, setProject] = useState(0);
    const [clients, setClients] = useState(0);
    const [courses, setCourses] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (experience < 2) {
                setExpeirience(prevState => prevState + 1)
            }
            if (project < 14) {
                setProject(prevState => prevState + 1)
            }
            if (clients < 21) {
                setClients(prevState => prevState + 1)
            }
            if (courses < 9) {
                setCourses(prevState => prevState + 1)
            }
        }, 100)
        return () => {
            clearTimeout(timeout)
        };
    }, [experience, project, clients, courses]);

    return (
        <div className={css.progress}>
            <div className={css.block}>
                <span className={css.number}>{experience}</span>
                <span className={css.text}>Года опыта и практики</span>
            </div>
            <div className={css.block}>
                <span className={css.number}>{project}</span>
                <span className={css.text}>Завершенных проектов</span>
            </div>
            <div className={css.block}>
                <span className={css.number}>{courses}</span>
                <span className={css.text}>Курсов и сертификатов</span>
            </div>
            <div className={css.block}>
                <span className={css.number}>{clients}</span>
                <span className={css.text}>Счастливых клиентов</span>
            </div>
        </div>
    );
}

export default Progress;
