import { createSlice } from '@reduxjs/toolkit';

// whith mobile sidebar too ....
const commonParams = createSlice({
    name: "commonParams",
    initialState: {
        height: 0,
        mobile: false,
        lang: 'ru',
    },
    reducers: {
        setHeight(state, action) {
            state.height = action.payload
        },
        setMobile(state, action) {
            state.mobile = action.payload
        },
        setLang(state, action){
            state.lang = action.payload
        }
    }
})

export const { setHeight, setMobile, setLang } = commonParams.actions

export default commonParams.reducer