// React
import React, { useEffect, useState } from 'react';
// Slick slider
import Slider from 'react-slick';
import sliderSettings from './SliderSettings';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// My css
import css from './Reviews.module.css';
import './Reviews.css';
// My Component
import ReviewBlock from '../ReviewBlock/ReviewBlock';
import Loader from '../../../Others/Loader/Loader';
// GraphQL
import { myReviews } from './Queries';
import { useQuery } from '@apollo/client';


const Reviews = () => {

    const { loading, error, data } = useQuery(myReviews)
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if (!loading && !error) {
            setReviews(data.reviewsCollection.items)
        } else {
            console.log(error)
        }
    }, [data]);

    return (
        <div className={css.reviews} >
            <div className={css.title}>
                <h2>Рекомендации</h2>
            </div >
            <Slider {...sliderSettings} >
                {reviews ?
                    reviews.map((review, id) => <ReviewBlock key={id} review={review} />)
                    : <Loader />
                }
            </Slider>
        </div >
    );
}

export default Reviews;
