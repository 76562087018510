import React from 'react';
import css from './NavbarTop.module.css';
import { useDispatch } from 'react-redux';
import { setShowMobileRightSidebar } from '../../../redux/reducers/ShowMobileRightSidebar';
import { setShowLeftSidebar } from '../../../redux/reducers/ShowMobileLeftSidebar';
import { setMobile } from '../../../redux/reducers/CommonParams';
import { withRouter } from 'react-router-dom';

const NavbarTop = React.memo(({ history: { location: { pathname } } }) => {

    const dispatch = useDispatch()
    
    return (
        <nav className={css.navbar}>
            <div className={css.leftIcon} onClick={() => {
                dispatch(setShowLeftSidebar(true))
                dispatch(setMobile(true))
            }}>
                <div className={css.dote} />
                <div className={css.dote} />
                <div className={css.dote} />
            </div>
            <div>
                {pathname === '/blog' && 'Блог'}
                {pathname.indexOf('post')!== -1 && 'Статья'}
                {pathname === '/' && 'Главная'}
            </div>
            <div className={css.rightIcon} onClick={() => {
                dispatch(setShowMobileRightSidebar(true))
            }}>
                <div className={css.line} />
                <div className={css.line} />
                <div className={css.line} />
            </div>
        </nav >
    );
})

export default withRouter(NavbarTop);
