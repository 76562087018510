// React
import React, {  useEffect, useRef, useState } from 'react'
// My components
import Loader from '../../Others/Loader/Loader';
import Footer from '../SinglePage/Footer/Footer';
import NewsBlock from '../SinglePage/NewsBlock/NewsBlock';
import MyScroll from '../../Others/MyScroll/MyScroll';
// Css transition
import { CSSTransition } from 'react-transition-group';
// My css
import css from './BlogPage.module.css';
// GraphQL
import { useQuery } from '@apollo/client';
import { GET_POSTS } from './Queries';
import { useDispatch } from 'react-redux';
import { reset } from '../../../redux/reducers/Scroll';

const clientWidth = document.documentElement.clientWidth

const BlogPage = React.memo(({ myStyle }) => {

    const dispatch = useDispatch()
    const myRef = useRef()

    const [widthScreen] = useState(clientWidth);
    const [inProp, setInProp] = useState(false);

    const [limit] = useState(6);
    const [skip] = useState(0);

    const [currentPage, setCurrentPage] = useState(0)
    const [arrayPaginatioins, setArrayPaginatioins] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(1);

    const [articles, setArticles] = useState(null);

    const { data, error, fetchMore } = useQuery(GET_POSTS, {
        variables: {
            skip: skip,
            limit: limit
        }
    })

    function changePage(currentPage) {
        // Go Up of component
        if ((currentPage >= 0) && (currentPage < (arrayPaginatioins.length))) {
            // Reset page
            myRef.current.style.transform = 'translateY(0px)'
            // Update page
            // Hide component
            setInProp(false) // 300ms animation...
            new Promise(resolve => {
                setTimeout(() => {
                    // doing new request
                    fetchMore({
                        variables: {
                            skip: (6 * currentPage),
                            limit
                        },
                    }).then(fetchMoreResult => {
                        setArticles(fetchMoreResult.data.postsCollection.items)
                        setCurrentPage(currentPage)
                    })
                    // Show component
                    setInProp(true)
                    resolve()
                }, 300)
            })
                .then(() => { setTimeout(() => dispatch(reset()), 300) })

        }
        else return
    }

    useEffect(() => {
        if (data) {
            setArticles(data.postsCollection.items)
            setTotalPageCount(Math.ceil(data.postsCollection.total / limit))
            setArrayPaginatioins(Array.from(Array(totalPageCount).keys()))
        } else {
            console.log('err:', error)
        }
    }, [data, totalPageCount])

    useEffect(() => {
        setInProp(true)
        dispatch(reset())
    }, [])

    return (
        <MyScroll myStyle={myStyle} step={175}>
            <main ref={myRef} className="main">

                <div className={css.title}>
                    <h1>Статьи</h1>
                </div>

                {/* Вывод статьей */}
                <div className={css.articles}>
                    {!articles
                        ? <CSSTransition in={inProp} classNames="component" timeout={200} appear={true}>
                            <Loader />
                        </CSSTransition>
                        : articles.map((article, id) => (
                            <CSSTransition in={inProp} classNames="component" timeout={{ appear: 1000, enter: 400, exit: 300 }} key={id} appear={true} >
                                <NewsBlock {...article} blog={true} />
                            </CSSTransition>))
                    }
                </div>

                {/* Пагинация */}
                <div className={css.paginate}>

                    <span className={css.prevPage} onClick={() => changePage(currentPage - 1)} >
                        {(widthScreen < 500) && '<'}
                        {(widthScreen < 700 && widthScreen > 500) && '< Предыдущая'}
                        {(widthScreen > 700) && '< Предыдущая страница'}
                    </span>

                    <span className={css.page}>
                        {(arrayPaginatioins.length > 0)
                            ? arrayPaginatioins.map((_, id) => {
                                const currentPaginate = id
                                return (
                                    <span
                                        key={id} className={(currentPage === currentPaginate) ? css.activePage : css.page}
                                        onClick={() => changePage(currentPaginate)}
                                    >
                                        {currentPaginate + 1}
                                    </span>
                                )
                            })
                            : <Loader />
                        }
                    </span>

                    <span className={css.nextPage} onClick={() => changePage(currentPage + 1)}>
                        {(widthScreen < 500) && '>'}
                        {(widthScreen < 700 && widthScreen > 500) && 'Следующая >'}
                        {(widthScreen > 700) && 'Следующая страница >'}
                    </span>

                </div>
                <div className={css.footer}>
                    <Footer />
                </div>
            </main >
            <div className={css.backgroundImage}>
                <div className={css.background} />
            </div>
        </MyScroll>
    )
})

export default BlogPage