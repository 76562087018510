import React from 'react';
import { whatsappNumber } from '../../../constants';
import style from './Button.module.css';

const Button = ({ text }) => {
    return (
        <>
            {text === whatsappNumber ?
                <a className={style.button} href="https://wa.me/905318598240" target="_blank" rel="noreferrer noopener ">
                    {text}
                </a>
                : 
                <div className={style.button}> {text} </div>
            }
        </>
    );
}

export default Button;
