import React from 'react';
import css from './Contacts.module.css';

const Contacts = () => {
    return (
        <div className={css.contacts}>
            <div className={css.title}>
                <h2>Связаться со мной</h2>
            </div>
            <div className={css.blocks}>
                <div className={css.location}>
                    <div className={css.row}>
                        <span style={{ color: "white", textAlign: "left", marginBottom: "15px" }}>Странна</span>
                        <span>Россия</span>
                    </div>
                    <div className={css.row}>
                        <span style={{ color: "white", textAlign: "right" }}>Город</span>
                        <span>Томск</span>
                    </div>
                </div >
                <div className={css.communication}>
                    <div className={css.row}>
                        <span style={{ color: "white", textAlign: "left", marginBottom: "15px" }}>E-mail</span>
                        <span>ESSeleznev@Gmail.com</span>
                    </div>
                    <div className={css.row}>
                        <span style={{ color: "white", textAlign: "right" }}>Telegram</span>

                        <a href="https://t.me/johnny_sel" target="_blank" rel="noreferrer noopener">
                            <span>Johnny_sel</span>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Contacts;
