import React from 'react';
// Css
import css from './HistoryBlock.module.css'
// Component
import Button from '../../../Others/Button/Button'
import Tooltip from '../../../Others/Tooltip';

// Component Function
const HistoryBlock = React.memo(({ index, lastElement, education, profession }) => {

    // JSX
    return (
        <div className={css.block}>
            <div className={css.circle}></div>
            <div className={index === lastElement ? css.lastLine : css.line}></div>
            <div className={css.headerBlock}>
                <div className={css.leftBlock}>
                    <div className={css.title}>{education ? education.title : profession.title}</div>
                    <div className={css.underTitle}>{education ? education.specialty : profession.post}</div>
                </div>
                <div className={css.rightBlock}>
                    <div className={css.date}>{education ? education.period : profession.period}</div>
                </div>
            </div>
            <div className={css.content}>{education ? education.description : profession.description}</div>
            <a href={education ? education.link : null} target="_blank" rel="noreferrer noopener " style={education && education.link ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}>
                {education
                    ? <Button text="СМОТРЕТЬ КУРС >" />
                    : <Tooltip text="Страница еще не готова"><Button text="ПОДРОБНЕЕ >" /></Tooltip>
                }
            </a>
        </div>
    );
})

export default HistoryBlock;
