import { combineReducers, configureStore } from '@reduxjs/toolkit';
import scrollSidebar from './reducers/ScrollSidebar.js';
import scroll from './reducers/Scroll';
import showMobileRightSidebar from './reducers/ShowMobileRightSidebar';
import showRightSidebar from './reducers/ShowRightSidebar';
import showMobileLeftSidebar from './reducers/ShowMobileLeftSidebar';
import commonParams from './reducers/CommonParams';


const rootReducer = combineReducers({
    scroll,
    scrollSidebar,
    showMobileRightSidebar,
    showRightSidebar,
    showMobileLeftSidebar,
    commonParams
})

const store = configureStore({ reducer: rootReducer })

export default store