import { createSlice } from "@reduxjs/toolkit";

const scrollSlice = createSlice({
    name: "scroll",
    initialState: {
        counter: 0,
        step: 0,
        translateValue: 0,
        startPoint: 0,
        endPoint: 0,
        heightScroll: 0,
        heightChildScroll: 0,
        sensitivity: 20,
        touchStart: { x: 0, y: 0 },
        touchPosition: { x: 0, y: 0 },
    },
    reducers: {
        increment(state) {
            state.translateValue = state.translateValue + state.step
            state.counter = state.counter + state.step
        },
        decrement(state) {
            state.translateValue = state.translateValue - state.step
            state.counter = state.counter - state.step
        },
        reset(state) {
            state.translateValue = 0
            state.counter = 0
            state.endPoint = 0
            state.heightChildScroll = 0
            state.heightScroll = 0

        },
        setHeightScroll(state, action) {
            state.heightScroll = action.payload
        },
        setHeightChildScroll(state, action) {
            state.heightChildScroll = action.payload
        },
        setEndPoint(state) {
            state.endPoint = state.heightScroll - state.heightChildScroll
        },
        setStep(state, action) {
            state.step = action.payload
        },
        setTouchStart(state, action) {
            state.touchStart = action.payload
            state.touchPosition = action.payload
        },
        setTouchPosition(state, action) {
            state.touchPosition = action.payload
        },
    }
})

export const {
    increment,
    decrement,
    reset,
    setHeightScroll,
    setHeightChildScroll,
    setEndPoint,
    setStep,
    setTouchStart,
    setTouchPosition,
} = scrollSlice.actions

export default scrollSlice.reducer
