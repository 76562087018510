import React, { useState, useEffect } from 'react';
// Components
import ItemPortfolio from '../ItemPortfolio/ItemPortfolio';
import Loader from '../../../Others/Loader/Loader';
// Css
import css from './Portfolio.module.css';
// GraphQl
import { useQuery } from '@apollo/client';
import { myProjects } from './Queries';

const Portfolio = () => {

    // Get data from GraphQL
    const { loading, error, data } = useQuery(myProjects)

    // Declarate varibales
    const [projects, setProjects] = useState([]);

    // After mounting component
    useEffect(() => {
        if (!loading && !error) {
            setProjects(data.projectsCollection.items)
        } else {
            console.log(error)
        }
    }, [data])

    // JSX
    return (
        <div className={css.portfolio}>
            <div className={css.title}>
                <h2>Мои работы</h2>
            </div>
            <div className={css.blocks}>
                {loading
                    ? <Loader />
                    : projects.map((project, id) => <ItemPortfolio key={id} project={project} />)
                }
            </div>
        </div>
    );
}

export default Portfolio;