// React
import React, { useEffect, useState } from 'react';
// My css
import css from './MyHistory.module.css';
// My components
import HistoryBlock from '../HistoryBlock/HistoryBlock';
import Loader from '../../../Others/Loader/Loader'
// GrapQL
import { getMyEducations, getMyProfessions } from './Queries';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';

const MyHistory = React.memo(({ getMyEducations, getMyProfessions }) => {
    const [educations, setEducations] = useState(null)
    const [professions, setProfessions] = useState(null)

    useEffect(() => {
        if (!getMyEducations.loading && !getMyEducations.error) {
            setEducations(getMyEducations.educationCollection.items)
        } else {
            console.log(getMyEducations.error)
        }
    }
        , [getMyEducations])

    useEffect(() => {
        if (!getMyProfessions.loading && !getMyProfessions.error) {
            setProfessions(getMyProfessions.professionsCollection.items)
        } else {
            console.log(getMyProfessions.error)
        }
    }, [getMyProfessions])

    return (
        <div className={css.wrap}>
            <div className={css.myHistory}>

                <div className={css.content}>
                    <div className={css.title}>
                        <h2>Образование</h2>
                    </div>
                    <div className={css.blocks}>
                        {
                            educations ?
                                educations.map((education, id) => {
                                    const lastElement = educations.length - 1
                                    return <HistoryBlock key={id} index={id} lastElement={lastElement} education={education} />
                                })
                                : <Loader />
                        }
                    </div>

                </div>
                <div className={css.content}>
                    <div className={css.title}>
                        <h2>Профессиональная деятельность</h2>
                    </div>
                    <div className={css.blocks}>
                        {
                            professions ?
                                professions.map((profession, id) => {
                                    const lastElement = professions.length - 1
                                    return <HistoryBlock key={id} index={id} lastElement={lastElement} profession={profession} />
                                })
                                : <Loader />
                        }
                    </div>
                </div>

            </div>
        </div>

    );
})

export default compose(
    graphql(getMyEducations, { name: "getMyEducations" }),
    graphql(getMyProfessions, { name: "getMyProfessions" }),
)(MyHistory);