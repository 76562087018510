import css from './News.module.css';

const NextArrow = ({ className, style, onClick }) => {
    return (
        <div className={`${className} ${css.next}`} onClick={onClick} />
    )
}

const PrevArrow = ({ className, style, onClick }) => {
    return (
        <div className={`${className} ${css.prev}`} onClick={onClick} />
    )
}

export const sliderSettings = {
    className: "sliderNews variable-width",
    dots: true,
    infinite: true,
    width: '333',
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '5px' }}>
                <ul className={css.dots}>{dots}</ul>
            </div>
        )
    },
    customPaging: () => {
        const myStyles = {
            background: '#ffc107',
            width: '30px',
            height: '5px',
            display: 'inline-block',
            borderRadius: '25%',
            cursor: 'pointer'
        }
        return (
            <span style={myStyles} />
        )
    }
}

