import React, { useEffect, useRef, useState } from 'react';
// My style
import Styles from './SidebarLeft.module.css';
// My Component
import MyScroll from '../../Others/MyScroll/MyScroll';
// Redux
import { closeLeftSidebar } from '../../../redux/reducers/ShowMobileLeftSidebar';
import { setSbTranslateValue } from './../../../redux/reducers/ScrollSidebar'
import { useDispatch, useSelector } from 'react-redux';
import { setMobile } from '../../../redux/reducers/CommonParams';

// Get classes
const { sidebar, fotos, myAvatar, name, whoiam, hr, line_skill, closeIcon, line,
    knowledge, info, right, language, circle_lang,
    protsenty, skills, skill, title_skill, inline, insrumental,
    download, load, social_icons, insta, vk, linkin, whatsapp, skype, telegram } = Styles

// Document width
const clientWidth = document.documentElement.offsetWidth

// Component
const SidebarLeft = React.memo(({ open, mobileStep, display }) => {

    const { height } = useSelector(({ commonParams }) => commonParams)
    const { sbEndPoint } = useSelector(({ scrollSidebar }) => scrollSidebar)
    const dispatch = useDispatch()

    const [htmlCount, setHtmlCount] = useState(0);
    const [cssCount, setCssCount] = useState(0);
    const [jsCount, setJSCount] = useState(0);
    const [reactCount, setReactCount] = useState(0);
    const [nodeCount, setNodeCount] = useState(0);
    const [mongoCount, setMongoCount] = useState(0);
    const [seoCount, setSeoCount] = useState(0);

    const [myStyle, setMyStyle] = useState({ width: '100%', height: 'fit - content' });

    const refOverBlock = useRef()
    const refUnderBlock = useRef()

    // useEffect(() => {
    //     const timeOut_2sec = setTimeout(() => {
    //         dispatch(setSbTranslateValue(-400))
    //         setTimeout(() => {
    //             dispatch(setSbTranslateValue(0))
    //         }, 1000)
    //     }, 8000)
    //     return () => clearTimeout(timeOut_2sec)
    // }, [sbEndPoint])

    useEffect(() => {
        const result = height - refOverBlock.current.offsetHeight - refUnderBlock.current.offsetHeight
        setMyStyle({ ...myStyle, height: result + 'px' })
    }, [height])

    useEffect(() => {
        const timeOut =
            setTimeout(() => {
                (htmlCount < 80) && setHtmlCount(prev => prev + 1);
                (cssCount < 80) && setCssCount(prev => prev + 1);
                (jsCount < 60) && setJSCount(prev => prev + 1);
                (reactCount < 60) && setReactCount(prev => prev + 1);
                (nodeCount < 35) && setNodeCount(prev => prev + 1);
                (mongoCount < 50) && setMongoCount(prev => prev + 1);
                (seoCount < 100) && setSeoCount(prev => prev + 1);
            }, 50)
        return () => {
            clearTimeout(timeOut)
        };
    }, [htmlCount, cssCount, jsCount, reactCount, nodeCount, mongoCount, seoCount]);


    return (
        <aside className={sidebar} style={{ height: `${height}px`, display }}>
            {open &&
                <div className={closeIcon} onClick={() => {
                    dispatch(closeLeftSidebar(clientWidth))
                    dispatch(setMobile(false))
                }}>
                    <div className={line}></div>
                    <div className={line}></div>
                </div>
            }
            <div className={fotos} ref={refOverBlock}>
                <div className={myAvatar}></div>
                <span className={name}>Evgenii Seleznev</span>
                <span className={whoiam}>Front-end Developer,<br /> Web Master</span>
            </div>
            <MyScroll sbStyle={myStyle} step={mobileStep ? 100 : 150} sb={true}>
                <div className={knowledge}>
                    <div className={info}>
                        <div>
                            <span>Резиденция</span>
                            <span>Город</span>
                            <span>Возраст</span>
                        </div>
                        <div className={right}>
                            <span>Россия</span>
                            <span>Томск</span>
                            <span>32</span>
                        </div>
                    </div>
                    <div className={hr}></div>
                    <div className={language}>
                        <div className={circle_lang}><span className={protsenty}>75</span><span>%</span></div>
                        <span>English</span>
                    </div>
                    <div className={hr}></div>
                    <div className={skills}>
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>HTML5</div>
                                <div className={right}>
                                    <span className={protsenty}>{htmlCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${htmlCount}%` }}></div>
                            </div>
                        </div>
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>CSS</div><div className={right}>
                                    <span className={protsenty}>{cssCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${cssCount}%` }}> </div>
                            </div>
                        </div>
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>JavaScript</div><div className={right}>
                                    <span className={protsenty}>{jsCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${jsCount}%` }}></div>
                            </div>
                        </div>
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>React JS, Redux</div><div className={right}>
                                    <span className={protsenty} > {reactCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${reactCount}%` }}></div>
                            </div>
                        </div >
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>MUI, Ant Design </div><div className={right}>
                                    <span className={protsenty} > {reactCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${reactCount}%` }}></div>
                            </div>
                        </div >
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>Node JS, Express</div><div className={right}>
                                    <span className={protsenty}>{nodeCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${nodeCount}%` }}></div>
                            </div>
                        </div >

                        <div className={skill}>
                            <div className={title_skill}>
                                <div>Mongo DB, Mongoose</div><div className={right}>
                                    <span className={protsenty}>{mongoCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${mongoCount}%` }}></div>
                            </div>
                        </div >
                        <div className={skill}>
                            <div className={title_skill}>
                                <div>SEO</div><div className={right}>
                                    <span className={protsenty}>{seoCount}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div className={inline}>
                                <div className={line_skill} style={{ width: `${seoCount}%` }}></div>
                            </div>
                        </div >
                    </div >
                    <div className={hr}></div>

                    <div className={insrumental}>
                        <span> BEM methodology</span>
                        <span> Bootstrap 4, MUI, Ant Design</span>
                        <span> Sass, Gulp</span>
                        <span> Git, GitHub</span>
                        <span> Figma</span>
                    </div>
                    <div className={hr}></div>
                    <div className={download}>
                        <a href="https://tomsk.hh.ru/resume_converter/%D0%A1%D0%B5%D0%BB%D0%B5%D0%B7%D0%BD%D0%B5%D0%B2%20%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D0%B9%20%D0%A1%D0%B5%D1%80%D0%B3%D0%B5%D0%B5%D0%B2%D0%B8%D1%87.doc?hash=02205ac6ff05159cb40039ed1f667332777363&type=rtf&hhtmSource=resume_view&hhtmFrom=user_resumes_list">Скачать резюме  <span className={load}></span></a>
                    </div >
                </div >
            </MyScroll>

            <div className={fotos} ref={refUnderBlock}>
                <div className={social_icons}>
                    <a href="https://t.me/johnny_sel" target="_blank" rel="noreferrer noopener "><span className={telegram}></span></a>
                    <a href="https://wa.me/905318598240" target="_blank" rel="noreferrer noopener "><span className={whatsapp}></span></a>
                    <a href="skype:esseleznev?chat" target="_blank" rel="noreferrer noopener "><span className={skype}></span></a>
                </div>
            </div>
        </aside >
    );
})

export default SidebarLeft;

