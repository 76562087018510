// React
import React, { useState, useRef, useEffect } from 'react';
// Css
import css from './ReviewBlock.module.css'
// Image
import female from '../../../../images/female.svg'
import male from '../../../../images/male.svg'

// -----------
// COMPONENT
// ---------
const ReviewBlock = React.memo(({ review }) => {

    // Create rating stars array
    let stars = Array(review.rate)
    stars = [...stars].map((_, i) => <li key={i}></li>)

    // Create width block for pass to parrent component
    const [widthBlock, setWidthBlock] = useState(0);
    const blockRef = useRef()

    useEffect(() => {
        // Set width
        setWidthBlock(blockRef.current.offsetWidth)
    }, [widthBlock]);

    // JSX
    return (
        <div ref={blockRef} className={css.block}>
            <div className={css.avatar}>
                <img src={(review.sex === 'male') ? male : female} alt="" />
            </div>
            <div className={css.title}>
                {review.author}
            </div>
            <div className={css.under_title}>
                Заказчик
            </div>
            <div className={css.content}>
                {review.text}
            </div>
            <div className={css.review}>
                <div className={css.rating}>
                    {stars}
                </div>
                <a href="https://work-zilla.com" rel="noreferrer" target="_blank">
                    <span className={css.source}>см. на Work-Zilla.com</span>
                </a>
            </div>
        </div>
    );
})

export default ReviewBlock;
