// React -----------------------------------------------/
import React, { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
// CSS -------------------------------------------------/
import css from './HomePage.module.css'
// My Components ------------------------------------------/
import NavbarTop from './NavbarTop/NavbarTop';
import SidebarRight from './SidebarRight/SidebarRight';
import SidebarLeft from './SidebarLeft/SidebarLeft'
import SidebarRightMobile from './SidebarRightMobile/SidebarRightMobile'
import SidebarLeftMobile from './SidebarLeftMobile/SidebarLeftMobile';
import Loader from '../Others/Loader/Loader';
import SinglePage from './SinglePage/SinglePage';
import BlogPage from './BlogPage/BlogPage';
import ArticlePage from './BlogPage/ArticlePage/ArticlePage';
// Redux
import { setHeight } from '../../redux/reducers/CommonParams';
import { useDispatch, useSelector } from 'react-redux';

// Lazy

// const SinglePage = React.lazy(() => import('./SinglePage/SinglePage'))
// const BlogPage = React.lazy(() => import('./BlogPage/BlogPage'))
// const ArticlePage = React.lazy(() => import('./BlogPage/ArticlePage/ArticlePage'))

// Height of document screen ------------------------------/ 
const clientHeight = document.documentElement.clientHeight
const clientWidth = document.documentElement.clientWidth

const HomePage = () => {
    const dispatch = useDispatch()
    const { mobile } = useSelector(state => state.commonParams)

    useEffect(() => {
        dispatch(setHeight((clientWidth <= 1324) ? clientHeight : clientHeight - 30))
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <div className={css.wrapper}>
                <SidebarLeftMobile />
                <NavbarTop />
                <div className="container" >
                    {!mobile && <SidebarLeft />}
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <Route exact path="/" component={SinglePage} />
                            <Route exact path="/blog" component={BlogPage} />
                            <Route path="/blog/article/:slug" component={ArticlePage} />
                        </Switch>
                    </Suspense>
                    <SidebarRight />
                </div>
            </div >
            <SidebarRightMobile />
        </div >
    )
}

export default HomePage